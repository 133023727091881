import { FormattedMessage, Link } from "gatsby-plugin-intl";
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import Breadcrumbs from "../../components/breadcrumbs";
import Layout from "../../components/layout";
import GroupSidePanel from "../../components/user-management/groups/group-side-panel";
import GroupsList from "../../components/user-management/groups/groups-members-list";

const ManageGroups = () => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <Layout>
      <Breadcrumbs
        parentPageUrl="/user-management"
        parentPageTitle={
          <FormattedMessage
            id="user-management"
            defaultMessage="User Management"
          />
        }
        currentPageTitle={
          <FormattedMessage id="manage_groups" defaultMessage="Manage Groups" />
        }
      ></Breadcrumbs>
      {loading ? (
        <div className="row mb-5">
          <div className="col">
            <h2 className="anchor-title">
              <FormattedMessage id="loading" />{" "}
            </h2>
            <Spinner animation="border" variant="secondary" role="status">
              <span className="visually-hidden">
                <FormattedMessage id="loading" />
              </span>
            </Spinner>
          </div>
        </div>
      ) : (
        <div>
          <div className="row justify-content-between mb-3">
            <h1 className="block-title col-6">
              <FormattedMessage
                id="manage_groups"
                defaultMessage="Manage Groups"
              />
            </h1>
          </div>
          <GroupSidePanel />
        </div>
      )}
    </Layout>
  );
};

export default ManageGroups;
