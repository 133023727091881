import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { Dispatch, SetStateAction, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import {
  addGroupMember,
  removeGroupMember,
} from "../../../services/userManagementService";
import useUserGroups from "../../../hooks/useUserGroups";
import filterRoles from "../../../utils/filterRoles";
import useUserDetail from "../../../hooks/useUserDetail";
import useUserInvitations from "../../../hooks/useUserInvitations";
import { FormattedMessage } from "gatsby-plugin-intl";
import AddUserToGroupModal from "./add-user-to-group-modal";
import getGroupDisplayName from "../../../utils/getGroupDisplayName";

const AddUserToGroupBtn = ({
  groupId,
  groupName,
  userId,
  isRole,
  setShowSuccessToast,
  setShowFailToast,
  setErrorMessage,
}: {
  groupId: string;
  groupName: string | undefined;
  userId: string;
  isRole: boolean;
  setShowSuccessToast: Dispatch<SetStateAction<boolean>>;
  setShowFailToast: Dispatch<SetStateAction<boolean>>;
  setErrorMessage: Dispatch<SetStateAction<string | undefined>>;
}) => {
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);

  const queryClient = useQueryClient();

  const { userDetailsData } = useUserDetail({
    userId,
  });

  const { userInvitationsQuery } = useUserInvitations({
    userId,
  });

  const { userGroupsData } = useUserGroups({ userId });

  const userRoles = filterRoles(userGroupsData);

  const addMutation = useMutation({
    mutationFn: () => {
      return addGroupMember(userId, groupId)
        .then((response) => {
          console.log(response);
          if (response.ok) {
            setShowSuccessToast(true);
            queryClient.invalidateQueries({
              queryKey: ["groups-members", groupId],
              exact: true,
            });
            queryClient.invalidateQueries({
              queryKey: ["user-groups", userId],
              exact: true,
            });
            queryClient.invalidateQueries({
              queryKey: ["groups", process.env.GATSBY_PPP_SPORT_LEAD_GROUP_ID],
              exact: true,
            });
          } else {
            setErrorMessage(response.statusText);
            setShowFailToast(true);
          }
        })
        .catch((error) => {
          setErrorMessage(error.name);
          setShowFailToast(true);
        });
    },
  });

  const removeMutation = useMutation({
    mutationFn: () => {
      return Promise.all(
        userRoles.map((userRole) => removeGroupMember(userId, userRole.id))
      )
        .then((responses) => {
          responses.map((response, index) => {
            if (response.ok) {
              queryClient.invalidateQueries({
                queryKey: ["groups-members", userRoles[index].groupId],
              });
              queryClient.invalidateQueries({
                queryKey: ["user-groups", userId],
                exact: true,
              });
            } else {
              setErrorMessage(response.statusText);
              setShowFailToast(true);
            }
          });
        })

        .catch((error) => {
          setErrorMessage(error);
          setShowFailToast(true);
        });
    },
  });

  const addUserToGroup = () => {
    if (userRoles?.length > 0 && isRole) {
      setShowModal(true);
    } else {
      addMutation.mutate();
    }
  };

  if (userInvitationsQuery.isLoading) {
    return (
      <Spinner animation="border" variant="secondary" role="status">
        <span className="visually-hidden">
          <FormattedMessage id="loading" />
        </span>
      </Spinner>
    );
  } else {
    if (userInvitationsQuery.data) {
      if (
        userInvitationsQuery.data.length === 0 ||
        userInvitationsQuery.data[0].acceptedDate
      ) {
        return (
          <>
            <Button
              onClick={addUserToGroup}
              type="button"
              className={`btn btn-secondary ${
                addMutation.isPending ? "disabled" : ""
              }`}
            >
              Add
            </Button>
            {userRoles && isRole && (
              <AddUserToGroupModal
                name={userDetailsData?.displayName}
                currentGroupName={getGroupDisplayName(
                  userRoles[0]?.id,
                  userRoles[0]?.displayName
                )}
                newGroupName={groupName}
                show={showModal}
                addMutation={addMutation}
                removeMutation={removeMutation}
                userRoles={userRoles}
                onHide={handleClose}
                handleClose={handleClose}
              ></AddUserToGroupModal>
            )}
          </>
        );
      } else {
         return <span>You cannot add this user - invitation expired.</span>;
      }
    } else {
      return <span>You cannot add this user - invitation pending or expired</span>;
    }
  }
};

export default AddUserToGroupBtn;
