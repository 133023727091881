import React, { useState } from "react";
import SearchUsers from "../search-users";
import AddUserToGroupList from "./add-user-to-group-list";
import useGroupMembers from "../../../hooks/useGroupMembers";

const AddUserToGroup = ({ groupId, groupName, isSport }: { groupId: string, groupName: string | undefined, isSport: boolean }) => {
  const [searchName, setSearchName] = useState<string | undefined>();

  const { groupMemberIds } = useGroupMembers({ groupId });

  return (
    <>
      <SearchUsers setSearchName={setSearchName} searchName={searchName} />
      <AddUserToGroupList
        groupId={groupId}
        groupName={groupName}
        searchName={searchName}
        groupMemberIds={groupMemberIds}
        isSport={isSport}
      />
    </>
  );
};

export default AddUserToGroup;
