import { FormattedMessage } from "gatsby-plugin-intl";
import React, { useMemo, useState } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import UsersTable from "../users-table";
import useGroupMembers from "../../../hooks/useGroupMembers";
import { Offcanvas } from "react-bootstrap";
import AddUserToGroup from "./add-user-to-group";
import RemoveUserFromGroupBtn from "./remove-user-from-group-btn";

const GroupMembersList = ({
  groupId,
  groupName,
  groupDescription,
  isActive,
  isSport,
}: {
  groupId: string;
  groupName: string | undefined;
  groupDescription: string | undefined;
  isActive: boolean;
  isSport: boolean;
}) => {
  const { groupMembersQuery } = useGroupMembers({ groupId });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const columns = useMemo(
    () => [
      {
        id: "name",
        header: "Name",
        cell: ({ row }: { row: any }) => {
          const user = row.original;
          if (!user.displayName) {
            return "";
          }
          return <span>{user?.displayName}</span>;
        },
        accessorKey: "displayName",
        enableColumnFilter: true,
      },
      {
        id: "email",
        header: "Email",
        cell: ({ row }: { row: any }) => {
          const user = row.original;
          if (!user.identities[0].issuerAssignedId) {
            return "";
          }
          return <span>{user.identities[0].issuerAssignedId}</span>;
        },
        accessorFn: (row: any) => {
          const identity: any = row.identities[0];
          return `${identity.issuerAssignedId}`;
        },
      },
      {
        id: "company-name",
        header: "Company",
        cell: ({ row }: { row: any }) => {
          const user = row.original;
          if (!user.companyName) {
            return "";
          }
          return <span>{user?.companyName}</span>;
        },
        accessorKey: "companyName",
      },
      {
        id: "actions",
        header: "",
        cell: ({ row }: { row: any }) => {
          const user = row.original;
          return <RemoveUserFromGroupBtn groupId={groupId} userId={user.id} />;
        },
        filterType: undefined,
      },
    ],
    []
  );

  if (!isActive) {
    <div>No data</div>;
  }

  return (
    <div>
      <div className="d-flex justify-content-between mb-3">
        <h2 className="">{groupName}</h2>
        <button
          className="btn btn-secondary skew-none "
          type="button"
          onClick={handleShow}
        >
          <FormattedMessage
            id="user_group_add"
            defaultMessage="Add new user to group"
          />
          <FontAwesomeIcon icon={faPlus} className="ms-2 fa-lg" />
        </button>
      </div>
      <p className="mb-3">{groupDescription}</p>
      <UsersTable usersQuery={groupMembersQuery} columns={columns} />
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement={"end"}
        className="offcanvas-subitem w-75"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <h2>Add user to group</h2>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <AddUserToGroup
            groupId={groupId}
            groupName={groupName}
            isSport={isSport}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default GroupMembersList;
