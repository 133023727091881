import React, { useState } from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import usePPPUsers from "../../../hooks/users/usePPPUsers";
import UsersTable from "../users-table";
import AddUserToGroupBtn from "./add-user-to-group-btn";
import SwToast from "../../toast/sw-toast";
import { useIntl } from "gatsby-plugin-intl";
import useSportLeads from "../../../hooks/users/useSportLeads";

const AddUserToGroupList = ({
  groupId,
  groupName,
  searchName,
  groupMemberIds,
  isSport,
}: {
  groupId: string;
  groupName: string | undefined;
  searchName: string | undefined;
  groupMemberIds: (string | undefined)[];
  isSport: boolean;
}) => {
  const { pppUsersQuery } = usePPPUsers();

  const { sportLeadsQuery } = useSportLeads();

  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showFailToast, setShowFailToast] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string>();

  const intl = useIntl();

  const columns = [
    {
      id: "name",
      header: "Name",
      cell: ({ row }: { row: any }) => {
        const user = row.original;
        if (!user.displayName) {
          return "";
        }
        return <span>{user?.displayName}</span>;
      },
      accessorKey: "displayName",
      enableColumnFilter: true,
    },
    {
      id: "email",
      header: "Email",
      cell: ({ row }: { row: any }) => {
        const user = row.original;
        if (!user.identities[0].issuerAssignedId) {
          return "";
        }
        return <span>{user?.identities[0].issuerAssignedId}</span>;
      },
      accessorFn: (row: any) => {
        const identity: any = row.identities[0];
        return `${identity.issuerAssignedId}`;
      },
    },
    {
      id: "company",
      header: "Company",
      cell: ({ row }: { row: any }) => {
        const user = row.original;
        if (!user.companyName) {
          return "";
        }
        return <span>{user?.companyName}</span>;
      },
      accessorKey: "companyName",
    },
    {
      id: "actions",
      header: "",
      cell: ({ row }: { row: any }) => {
        const user = row.original;
        if (groupMemberIds.includes(user.id))
          return (
            <>
              <span className="me-2">Added</span>
              <FontAwesomeIcon icon={faCheck} className="tick-success fa-lg" />
            </>
          );
        return (
          <AddUserToGroupBtn
            groupId={groupId}
            groupName={groupName}
            userId={user.id}
            isRole={!isSport}
            setShowSuccessToast={setShowSuccessToast}
            setShowFailToast={setShowFailToast}
            setErrorMessage={setErrorMessage}
          />
        );
      },
      filterType: undefined,
    },
  ];

  if (pppUsersQuery.isLoading || sportLeadsQuery.isLoading) {
    return <div className="loading-shimmer">Loading...</div>;
  }
  if (pppUsersQuery.isError && sportLeadsQuery.isError) {
    return <span>Error</span>;
  }
  
  return (
    <>
      <SwToast
        show={showFailToast}
        onClose={() => setShowFailToast(false)}
        header="Error adding group member"
        body={errorMessage || ""}
      ></SwToast>
      <SwToast
        show={showSuccessToast}
        onClose={() => setShowSuccessToast(false)}
        header={intl.formatMessage({
          id: "group.member.add.success",
          defaultMessage: "User added",
        })}
        body={intl.formatMessage({
          id: "group.member.add.success.message",
          defaultMessage: "User successfully added to group",
        })}
      ></SwToast>
      <div className="card">
        <UsersTable
          usersQuery={isSport ? sportLeadsQuery : pppUsersQuery}
          columns={columns}
          searchName={searchName}
        />
      </div>
    </>
  );
};

export default AddUserToGroupList;
