import React from "react";
import { FormattedMessage } from "gatsby-plugin-intl";
import { Modal, Button } from "react-bootstrap";
import { UseMutationResult } from "@tanstack/react-query";

const AddUserToGroupModal = ({
  name,
  newGroupName,
  currentGroupName,
  show,
  addMutation,
  removeMutation,
  onHide,
  handleClose,
}: {
  name: string;
  newGroupName: string | undefined;
  currentGroupName: string | undefined;
  show: boolean;
  addMutation: UseMutationResult<void, Error, void, unknown>;
  removeMutation: UseMutationResult<void, Error, void, unknown>;
  userRoles: any[];
  onHide: () => void;
  handleClose: () => void;
}) => {
  const onAdd = () => {
    removeMutation.mutate();
    addMutation.mutate();
  };
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage
            id="group.user.add.modal_header"
            defaultMessage="Add {name} to {newGroupName}"
            values={{ name, newGroupName }}
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormattedMessage
          id="group.user.add.modal_body"
          defaultMessage="This user is a {currentGroupName}. Adding {name} as a {newGroupName} will remove {name} as a {currentGroupName}. Are you sure you want to do this?"
          values={{ name, newGroupName, currentGroupName }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          <FormattedMessage id="cancel" defaultMessage="Cancel" />
        </Button>
        <Button variant="primary" onClick={onAdd}>
          <FormattedMessage id="group.user.add" defaultMessage="Add" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddUserToGroupModal;
