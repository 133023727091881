import React, { useState } from "react";
import { Col, Nav, Row, Spinner, Tab } from "react-bootstrap";
import GroupMembersList from "./groups-members-list";
import getGroupDisplayName from "../../../utils/getGroupDisplayName";
import useAllGroups from "../../../hooks/useAllGroups";
import { FormattedMessage } from "gatsby-plugin-intl";

const GroupSidePanel = () => {
  const [activeKey, setActiveKey] = useState<string | undefined>();

  const { allGroups, roles, sports, allGroupsQuery } = useAllGroups();

  // Keyboard navigation - setting a default tab
  const firstElement = React.useRef<any | null>(null);

  const setRefElement = (el: any) => {
    if (!el) return;
    firstElement.current = el;
  };

  return (
    <>
      <Tab.Container defaultActiveKey={activeKey}>
        <Row>
          <Col sm={2}>
            {allGroupsQuery.isLoading ? (
              <Spinner animation="border" variant="secondary" role="status">
                <span className="visually-hidden">
                  <FormattedMessage id="loading" />
                </span>
              </Spinner>
            ) : allGroupsQuery.error ? (
              <FormattedMessage
                id="error"
                defaultMessage="An error has occurred"
              />
            ) : (
              <Nav className="flex-column groups-side-panel">
                <details
                  open
                  onKeyDown={(e) => {
                    if (!activeKey) {
                      e.preventDefault();
                      firstElement.current.click();
                      firstElement.current.focus();
                    }
                  }}
                >
                  <summary className="fw-bold mb-2">Roles</summary>
                  {roles?.map((role, index) => (
                    <Nav.Item key={role.id}>
                      <Nav.Link
                        className="group-nav-link fw-normal"
                        ref={(ref: HTMLAnchorElement) => {
                          index === 0 && setRefElement(ref);
                        }}
                        eventKey={role.id}
                        onClick={() => setActiveKey(role.id)}
                      >
                        {getGroupDisplayName(role.id, role.displayName)}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </details>
                <details>
                  <summary className="fw-bold mb-2">Sports</summary>
                  {sports?.map((sport) => (
                    <Nav.Item key={sport.id}>
                      <Nav.Link
                        className="group-nav-link fw-normal"
                        eventKey={sport.id}
                        onClick={() => setActiveKey(sport.id)}
                      >
                        {getGroupDisplayName(sport.id, sport.displayName)}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </details>
              </Nav>
            )}
          </Col>
          <Col sm={10}>
            {activeKey ? (
              <Tab.Content>
                {allGroups?.map((group) => (
                  <Tab.Pane eventKey={group.id}>
                    {group.id && group.id === activeKey && (
                      <GroupMembersList
                        isActive={group.id === activeKey}
                        groupId={group.id}
                        groupName={getGroupDisplayName(
                          group.id,
                          group.displayName
                        )}
                        groupDescription={group.description || ""}
                        isSport={!roles.find((role) => role.id === group.id)}
                      />
                    )}
                  </Tab.Pane>
                ))}
              </Tab.Content>
            ) : (
              <div className="w-100 bg-light-grey d-flex justify-content-center align-items-center mb-3 groups-side-panel__placeholder">
                <p>Select a group</p>
              </div>
            )}
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
};

export default GroupSidePanel;
