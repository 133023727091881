import { useQuery, useQueryClient } from "@tanstack/react-query";
import { User } from "@microsoft/microsoft-graph-types-beta";
import { getGroupMembers } from "../services/userManagementService";

const useGroupMembers = ({ groupId }: { groupId: string }) => {
  const queryClient = useQueryClient();

  const groupMembersQuery = useQuery({
    queryKey: ["groups-members", groupId],
    queryFn: () => getGroupMembers(groupId),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    select: (data) =>
      data?.data.sort(
        (a: User, b: User) =>
          a.displayName?.localeCompare(b.displayName || "") || 0
      ),
  });

  const groupMembers = queryClient.getQueryData<User[]>(["groups", groupId]);

  const groupMemberIds = groupMembersQuery.data?.map((member) => member.id) || [];

  return {
    groupMembersQuery,
    groupMemberIds,
  };
};

export default useGroupMembers;
