import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { removeGroupMember } from "../../../services/userManagementService";
import SwToast from "../../toast/sw-toast";

const RemoveUserFromGroupBtn = ({
  groupId,
  userId,
}: {
  groupId: string;
  userId: string;
}) => {
  const queryClient = useQueryClient();
  const [showFailToast, setShowFailToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const mutation = useMutation({
    mutationFn: () => {
      return removeGroupMember(userId, groupId)
        .then((response) => {
          if (response.ok) {
            queryClient.invalidateQueries({
              queryKey: ["groups-members", groupId],
            });
            queryClient.invalidateQueries({
              queryKey: ["user-groups", userId],
              exact: true
            });
          } else {
            setErrorMessage(response.statusText);
            setShowFailToast(true);
          }
        })
        .catch((error) => {
          setErrorMessage(error);
          setShowFailToast(true);
        });
    },
  });

  return (
    <>
      <SwToast
        show={showFailToast}
        onClose={() => setShowFailToast(false)}
        header="Error removing group member"
        body={errorMessage || ""}
      ></SwToast>
      <Button
        onClick={() => mutation.mutate()}
        type="button"
        className="btn btn-secondary"
      >
        Remove
      </Button>
    </>
  );
};

export default RemoveUserFromGroupBtn;
