import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getGroupMembers } from "../../services/userManagementService";
import { User } from "@microsoft/microsoft-graph-types-beta";

// this uses the same method as useGroupMembers but is separated for convenience
const useSportLeads = () => {
  const queryClient = useQueryClient();

  const sportLeadsQuery = useQuery({
    queryKey: ["groups", process.env.GATSBY_PPP_SPORT_LEAD_GROUP_ID],
    queryFn: () =>
      getGroupMembers(process.env.GATSBY_PPP_SPORT_LEAD_GROUP_ID || ""),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    select: (data) =>
      data?.data.sort(
        (a: User, b: User) =>
          a.displayName?.localeCompare(b.displayName || "") || 0
      ),
  });

  const usersData = queryClient.getQueryData<any>([
    "groups",
    process.env.GATSBY_PPP_SPORT_LEAD_GROUP_ID,
  ]);

  const sportLeadsUsers =
    (usersData?.data?.sort(
      (a: { displayName: string }, b: { displayName: any }) =>
        a.displayName.localeCompare(b.displayName)
    ) as User[]) || [];

  return {
    sportLeadsQuery,
    sportLeadsUsers,
  };
};

export default useSportLeads;
