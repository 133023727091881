import React, { ReactElement } from "react";
import { Link } from "gatsby-plugin-intl";

interface Props {
  parentPageTitle: ReactElement;
  parentPageUrl: string;
  currentPageTitle: ReactElement;
}

const Breadcrumbs = (props: Props) => (
  <nav aria-label="breadcrumb">
    <ol className="breadcrumb">
      <li className="breadcrumb-item">
        <Link to={props.parentPageUrl}> {props.parentPageTitle}</Link>
      </li>
      <li className="breadcrumb-item active" aria-current="page">
        {props.currentPageTitle}
      </li>
    </ol>
  </nav>
);

export default Breadcrumbs;
